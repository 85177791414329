<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <LoadingBar
      v-if="loading === true || error.message.length"
      :error="error.message"
    />
    <ve-stepper v-model="currentStep" horizontal v-else>
      <v-stepper-items>
        <v-stepper-header
          style="display: flex; align-items: center; justify-content: center; width: 75%; margin: auto; border: none; box-shadow: none;"
        >
          <template v-for="(step, n) in newSteps">
            <ve-stepper-step
              :key="`step-${n}`"
              :complete="currentStep > n + 1"
              :step="n + 1"
              class="py-2"
              style="position: relative; border-radius: 18px;"
            >
              <div
                v-if="n === 0"
                style="position: absolute; left: 15px; top: 10%; padding: 10px; border-radius: 50%; background-color: #E0E0E7;"
                :style="{
                  backgroundColor: currentStep > n ? '#2B5B3C' : '#808080'
                }"
              >
                <v-img
                  src="@/assets/sample-info-icon.svg"
                  style="width: 13px; height: 13px; background-color: #E0E0E7;"
                  alt="icon"
                ></v-img>
              </div>

              <div
                v-if="n === 1"
                style="position: absolute; left: 15px; top: 10%; padding: 10px; border-radius: 50%; background-color: #E0E0E7;"
                :style="{
                  backgroundColor: currentStep > n ? '#2B5B3C' : '#808080'
                }"
              >
                <v-img
                  src="@/assets/test-incomplete.svg"
                  style="width: 13px; height: 13px;"
                  alt="icon"
                ></v-img>
              </div>

              <div
                v-if="n === 2"
                style="position: absolute; left: 15px; top: 10%; padding: 10px; border-radius: 50%; background-color: #E0E0E7;"
                :style="{
                  backgroundColor: currentStep > n ? '#2B5B3C' : '#808080'
                }"
              >
                <v-img
                  src="@/assets/test-incomplete.svg"
                  style="width: 13px; height: 13px;"
                  alt="icon"
                ></v-img>
              </div>

              <div
                v-if="n === 3"
                style="position: absolute; left: 15px; top: 10%; padding: 10px; border-radius: 50%; background-color: #E0E0E7;"
                :style="{
                  backgroundColor: currentStep > n ? '#2B5B3C' : '#808080'
                }"
              >
                <v-img
                  src="@/assets/test-incomplete.svg"
                  style="width: 13px; height: 13px;"
                  alt="icon"
                ></v-img>
              </div>

              <div
                v-if="n === 4"
                style="position: absolute; left: 15px; top: 10%; padding: 10px; border-radius: 50%; background-color: #E0E0E7;"
                :style="{
                  backgroundColor: currentStep > n ? '#2B5B3C' : '#808080'
                }"
              >
                <v-img
                  src="@/assets/review-incomplete.svg"
                  style="width: 13px; height: 13px;"
                  alt="icon"
                ></v-img>
              </div>

              <div
                v-if="n === 5"
                style="position: absolute; left: 15px; top: 10%; padding: 10px; border-radius: 50%; background-color: #E0E0E7;"
                :style="{
                  backgroundColor: currentStep > n ? '#2B5B3C' : '#808080'
                }"
              >
                <v-img
                  src="@/assets/shipping-incomplete.svg"
                  style="width: 13px; height: 13px;"
                  alt="icon"
                ></v-img>
              </div>
              <v-stepper-item>
                {{ step.title }}
              </v-stepper-item>
            </ve-stepper-step>
            <v-divider
              :key="`step-divider-${n}`"
              style="background-color: #2B5B3C;"
              v-if="n < newSteps.length - 1"
            ></v-divider>
          </template>
        </v-stepper-header>
        <template v-for="(step, n) in newSteps">
          <!-- <v-stepper-step :key="`step-${n}`" :complete="currentStep > n" :step="n + 1" class="py-2">
            {{ step.title }}
          </v-stepper-step> -->
          <v-stepper-content
            :key="`step-content-${n}`"
            :step="n + 1"
            style="margin-top: 4rem;"
          >
            <!-- <div>{{ active_sample }}</div> -->
            <!-- <div>{{ active_sample.shipment_id }}</div>

            <div>activeSampleShipmentId {{ activeSampleShipmentId }}</div>

            <div>{{ active_sample.id }}</div> -->

            <!-- <div>This is step {{ currentStep }}</div>
            <div>Shipment id {{ activeSampleShipmentId }}</div> -->

            <!-- 
            <div>{{ active_sample.shipment }}</div>
            <div>{{ active_sample.shipments }}</div> -->
            <!-- <div>{{ active_sample.shipment._joinData.shipment_id }}</div> -->

            <div class="v-footer no-gutters" style="margin-bottom: 40px;">
              <LoadingBar v-if="loading === true" />
              <template v-else>
                <v-row no-gutters dense>
                  <v-col>
                    <v-btn
                      v-if="currentStep > 1"
                      color="primary"
                      @click="previousStep"
                    >
                      Previous
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col>
                    <v-btn
                      v-if="routeSampleId && currentStep !== newSteps.length"
                      color="primary"
                      @click="saveAndExit(n + 1)"
                      :disabled="!canContinue"
                      class="float-right mb-1 ml-2"
                    >
                      Save and Exit
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="nextStep(n + 1)"
                      :disabled="!canContinue"
                      class="float-right mb-1 ml-2"
                    >
                      {{ nextStepBtnLabel }}
                    </v-btn>
                    <v-btn
                      v-if="!routeSampleId && currentStep === newSteps.length"
                      @click="addAnotherSample()"
                      color="primary"
                      class="float-right mb-1 ml-2"
                    >
                      Add Another Sample
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </div>
            <component
              v-bind:is="step.component"
              :ref="step.component"
              v-if="currentStep === n + 1"
              :halt="halt"
              style="box-shadow: none !important; border: none !important;"
            />
            <div class="v-footer no-gutters">
              <LoadingBar v-if="loading === true" />
              <template v-else>
                <v-row no-gutters dense>
                  <v-col>
                    <v-btn
                      v-if="currentStep > 1"
                      color="primary"
                      @click="previousStep"
                    >
                      Previous
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col>
                    <v-btn
                      v-if="routeSampleId && currentStep !== newSteps.length"
                      color="primary"
                      @click="saveAndExit(n + 1)"
                      :disabled="!canContinue"
                      class="float-right mb-1 ml-2"
                    >
                      Save and Exit
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="nextStep(n + 1)"
                      :disabled="!canContinue"
                      class="float-right mb-1 ml-2"
                    >
                      {{ nextStepBtnLabel }}
                    </v-btn>
                    <v-btn
                      v-if="!routeSampleId && currentStep === newSteps.length"
                      @click="addAnotherSample()"
                      color="primary"
                      class="float-right mb-1 ml-2"
                    >
                      Add Another Sample
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </div>
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </ve-stepper>
    <v-dialog v-model="refIdReminder" width="500" persistent>
      <v-card>
        <v-card-title>Please Note</v-card-title>
        <v-card-text>
          <p><b>Sample ID:</b> {{ active_sample.slug }}</p>
          <p>
            <b>Reference Code:</b>
            {{ active_sample.slug ? hexadecimalId : "none" }}
          </p>

          <!-- <p>
            {{ active_sample.sample_bag_barcode || "(None)" }}
          </p> -->
          Please include the Sample ID on your paperwork.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="handlleClickCancel" style="margin-left: 8px;"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            @click="nextStep(currentStep)"
            :disabled="!canContinue"
            class="mb-1 ml-2"
          >
            Finish
          </v-btn>

          <v-btn
            v-if="routeSampleId && currentStep !== newSteps.length"
            color="primary"
            @click="saveAndExit(currentStep)"
            :disabled="!canContinue"
            class="mb-1 ml-2"
          >
            Save and Exit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";
import PackageSelectionStep from "@/components/newSamples/SampleWizardSteps/PackageSelectionStep";
import BillingStep from "@/components/newSamples/SampleWizardSteps/BillingStep";
import SampleInformationStep from "@/components/newSamples/SampleWizardSteps/SampleInformationStep";
import ReviewStep from "@/components/newSamples/SampleWizardSteps/ReviewStep";
import ShipmentSelectionStep from "@/components/newSamples/SampleWizardSteps/ShipmentSelectionStep";
import ImagingStep from "@/components/newSamples/SampleWizardSteps/ImagingStep";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import hasIn from "lodash.hasin";
import VeStepper from "@/components/VeStepper";
import VeStepperStep from "@/components/VeStepper/VeStepperStep";

/**
 * @property {int} currentStep
 */
export default {
  name: "SampleWizard",
  components: {
    LoadingBar,
    PackageSelectionStep,
    BillingStep,
    SampleInformationStep,
    ReviewStep,
    ShipmentSelectionStep,
    ImagingStep,
    VeStepper,
    VeStepperStep
  },
  data() {
    return {
      loading: false,
      refIdReminder: false,
      valid: false, // This gets updated automatically, via the validator
      halt: false, // Allows a child component to halt continue by emitting updateHalt
      addAnother: false,
      hexadecimalId: ""
    };
  },
  computed: {
    ...mapGetters("sampleWizard", ["newSteps", "currentNewStepComponentTitle"]),
    ...mapState("samples", ["active_sample"]),
    ...mapFields("sampleWizard", [
      "wizard.currentStep",
      "wizard.returnStep",
      "wizard.error"
    ]),
    ...mapFields("samples", ["billingAgree"]),
    // Returns the current step component instance
    currentStepComponent() {
      if (!this.$refs) return undefined;
      // Find the current component (by ref="componentName")
      // currentStep is ahead of the index by 1
      let component = this.$refs[this.currentNewStepComponentTitle];
      if (!component) return undefined;
      if (Array.isArray(component)) {
        // The component will most likely be in an array, but it'll only have one item in it
        component = component[0];
      }
      return component;
    },
    canContinue() {
      if (this.error.message.length) {
        return false;
      }
      return this.valid;
    },
    nextStepBtnLabel() {
      if (this.currentStep === this.newSteps.length) return "Finished";
      // else if (this.currentNewStepComponentTitle === "ReviewStep")
      //   return "Add to Shipment";
      return "Continue";
    },
    activeSampleShipmentId() {
      return this.active_sample && this.active_sample.shipment_id
        ? this.active_sample.shipment_id
        : this.active_sample.shipment
        ? this.active_sample.shipment.id
        : null;
    },
    routeSampleId() {
      return hasIn(this.$route, "params.sampleId")
        ? this.$route.params.sampleId
        : null;
    }
  },
  methods: {
    ...mapActions("samples", ["setActive"]),
    ...mapActions("baseData", [
      "getLabs",
      "getTestPackageCategories",
      "getPackages",
      "getComponentCategories",
      "getTestComponents",
      "getFeedTypes",
      "getFeedClasses",
      "getCalibrateIngredients"
    ]),
    ...mapActions(["notify"]),
    ...mapActions("farms", ["getActiveFarms"]),
    ...mapMutations("samples", ["clearActive"]),
    ...mapMutations("sampleWizard", ["resetWizard"]),
    previousStep() {
      this.currentStep = this.currentStep - 1;
    },
    // Proceed to the next step
    nextStep(n, cb = undefined) {
      // If validation doesn't succeed, we can stop now
      if (!this.$refs.form.validate()) return false;
      if (this.currentNewStepComponentTitle === "ReviewStep") {
        this.storeHexadecimalId();
        this.refIdReminder = !this.refIdReminder;
      }
      if (this.refIdReminder) return false;
      // If component has beforeContinue method, execute it. Otherwise, we can safely set to true.
      const beforeContinue =
        this.currentStepComponent && this.currentStepComponent.beforeContinue
          ? this.currentStepComponent.beforeContinue()
          : true;
      // Resolve as a promise, that way we can do async things when needed
      Promise.resolve(beforeContinue).then(res => {
        // As long as we don't have a false response, we can proceed to the next step
        if (res) {
          // If returnStep contains a step, go to that step
          if (this.returnStep && typeof this.returnStep === "number") {
            this.currentStep = this.returnStep;
            this.returnStep = false; // Reset returnStep value
            return true;
          }
          // Default to next step iteration
          this.currentStep = n + 1;

          if (this.currentStep > this.newSteps.length) {
            // const shipmentId = this.activeSampleShipmentId;
            // Sample submitted. Clear active_sample and reset wizard
            this.clearActive();
            // this.resetWizard();
            // Only issue redirect if a callback wasn't defined
            if (typeof cb !== "function") {
              let message = "Sample Data Added Successfully";
              if (this.addAnother) window.location.reload();
              else {
                this.$router.push({
                  path: "/samples/newbrowse"
                });
                this.notify({ message, route: "/samples/new-sample-wizard" });
              }
            }
          }
          // execute the callback if one was provided
          if (typeof cb === "function") cb();
        }
      });
    },
    saveAndExit(step) {
      this.nextStep(step, () => {
        this.clearActive();
        this.resetWizard();
        this.$router.push("/samples/newbrowse");
      });
    },
    addAnotherSample() {
      this.addAnother = true;
      this.storeHexadecimalId();
      this.refIdReminder = true;
      // this.nextStep(this.newSteps.length, () => {
      //   this.setActive(null);
      // });
      // window.location.reload();
    },
    handlleClickCancel() {
      this.refIdReminder = false;
      this.addAnother = false;
    },
    storeHexadecimalId() {
      // Extract the last 6 characters from the slug
      const lastSixChars = this.active_sample.slug.slice(-6);

      const decimalValue = Number(lastSixChars);

      // Convert the decimal value to hexadecimal
      this.hexadecimalId = decimalValue.toString(16).toUpperCase();
    }
  },
  mounted() {
    this.loading = true;
    this.error.message = "";

    if (this.routeSampleId) {
      this.billingAgree = true;
      localStorage.setItem("stored_step", this.routeSampleId);
      if (this.routeSampleId !== localStorage.getItem("stored_step")) {
        this.currentStep = 1;
      }
    } else {
      this.currentStep = 1;
    }

    Promise.all([
      this.getLabs(),
      this.getTestPackageCategories(),
      this.getPackages(),
      this.getComponentCategories(),
      this.getTestComponents(),
      this.getActiveFarms(),
      this.getFeedTypes(),
      this.getFeedClasses(),
      this.getCalibrateIngredients(),
      // Initialize active sample
      this.setActive(this.routeSampleId)
    ]).then(data => {
      // Check for errors from any promise
      if (data.includes(false)) {
        this.error.message = "An error occurred while retrieving this sample";
      }
      this.loading = false;
    });

    // this.active_sample.lab_id =
    //   this.active_sample.shipment && this.active_sample.shipment.lab_id
    //     ? this.active_sample.shipment.lab_id
    //     : null;
  }
};
</script>
