<template>
  <StepComponent>
    <PackageSelection />
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/newSamples/SampleWizardSteps/StepComponent";
import PackageSelection from "@/components/newSamples/SampleWizardSteps/SampleStepComponents/PackageSelection";

import { mapActions, mapState } from "vuex";

export default {
  name: "PackageSelectionStep",
  components: { StepComponent, PackageSelection },
  methods: {
    ...mapActions("samples", ["updateSampleSet"]),
    ...mapActions(["notify"]),
    beforeContinue() {
      if (
        this.active_sample.test_components.length > 0 ||
        this.active_sample.test_packages.length > 0
      )
        return this.updateSampleSet("package").then(res => {
          return !!res;
        });
      else {
        const message = "Please Add Package(s) and/or Component(s)";
        this.notify({ message });
      }
    }
  },
  computed: {
    ...mapState("samples", ["active_sample"])
  }
};
</script>
