<template>
  <SampleImages :sample-id="active_sample.id" />
</template>

<script>
import SampleImages from "@/components/newSamples/components/SampleImages";
import { mapState } from "vuex";
export default {
  name: "ImagingStep",
  components: { SampleImages },
  computed: {
    ...mapState("samples", ["active_sample"])
  }
};
</script>

<style scoped></style>
