<template>
  <div>
    <template v-if="loading">
      <v-row dense>
        <v-col>
          <LoadingBar />
          <v-text-field
            type="hidden"
            value=""
            :rules="[v => !!v]"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="items.length">
      <!-- <v-row dense>
        <v-col cols="6">
          <v-autocomplete
            label="Add Existing Report Recipient"
            placeholder="Add Existing Report Recipient"
            v-bind:value="value"
            v-on:input="setShipmentValue($event)"
            :items="items"
            item-text="id"
            item-value="id"
            :clearable="clearable"
            no-data-text="No Available Shipments"
            auto-select-first
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                @click="data.select"
              >
                #{{ data.item.id }}
              </v-chip>
            </template>

<template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.id"></v-list-item-title>
                <v-list-item-subtitle>
                  Shipment Created Date: {{ data.item.created }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Samples in Shipment: {{ data.item.samples.length }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
</v-autocomplete>
</v-col>
<v-col>
  <v-btn variant="outlined"
    style="background-color: transparent; color: #006940; padding: 12px; box-shadow: none; border: 1px solid #006940; border-radius: 2px; margin-top: 20px; font-size: 23px;">
    +
  </v-btn>
</v-col>
</v-row> -->
      <v-row dense>
        <v-col cols="6">
          <v-autocomplete
            v-if="!disabled"
            label="Shipment"
            placeholder="Shipment"
            v-bind:value="value"
            v-on:input="setShipmentValue($event)"
            :items="items"
            item-text="id"
            item-value="id"
            :clearable="clearable"
            no-data-text="No Available Shipments"
            auto-select-first
            :disabled="disabled"
            :rules="[rules.required]"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                @click="data.select"
              >
                #{{ data.item.id }}
              </v-chip>
            </template>

            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.id"></v-list-item-title>
                <v-list-item-subtitle>
                  Shipment Created Date: {{ data.item.created }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Samples in Shipment: {{ data.item.samples.length }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-if="disabled"
            label="Shipment"
            placeholder="Shipment"
            v-bind:value="value"
            v-on:input="setShipmentValue($event)"
            :items="items"
            item-text="id"
            item-value="id"
            :clearable="clearable"
            no-data-text="No Available Shipments"
            auto-select-first
            :disabled="disabled"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                @click="data.select"
              >
                #{{ data.item.id }}
              </v-chip>
            </template>

            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.id"></v-list-item-title>
                <v-list-item-subtitle>
                  Shipment Created Date: {{ data.item.created }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Samples in Shipment: {{ data.item.samples.length }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <template v-if="!disabled">
            <v-btn
              variant="outlined"
              @click="handleClearShipment"
              color="primary"
              class="mt-5 ml-2"
            >
              Start New Shipment
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              variant="outlined"
              @click="handleEnableShipment"
              color="primary"
              class="mt-5 ml-2"
            >
              Add to an Existing Shipment
            </v-btn>
          </template>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" style="display: flex; gap: 15px; align-items: center;">
          <v-switch
            v-model="model"
            style="margin: 0;"
            :label="''"
            hide-details
            inset
          ></v-switch>
          <p style="margin-top: 15px;">
            Is this sample linked to another for testing? (This is only selected
            if the first sample is already logged.)
          </p>
        </v-col>
      </v-row>

      <v-row
        dense
        v-if="(!!initialInfo && !anyChanges) || (showCreateNew && !!value)"
      >
        <v-col v-if="!!initialInfo && !anyChanges"> {{ initialInfo }} </v-col>
        <!-- <v-col v-if="showCreateNew && !!value" class="text-center">
          <v-btn color="primary" @click="setShipmentValue(null)">
            Start New Shipment
          </v-btn>
        </v-col> -->
      </v-row>
    </template>
    <template v-if="!loading && !value && clearable">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-card-title>Shipment Info</v-card-title>
            <v-card-text v-if="!value" class="body-1">
              Adding to new shipment
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LoadingBar from "@/components/loaders/LoadingBar";

export default {
  name: "ShipmentSelect",
  props: {
    value: [String, Number],
    // lab_id: [String, Number],
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialInfo: {
      type: String,
      default: ""
    },
    showCreateNew: {
      type: Boolean,
      default: false
    }
  },
  components: { LoadingBar },
  data() {
    return {
      loading: false,
      anyChanges: false,
      items: [],
      model: true,
      rules: {
        required: v => !!v || "Shipment is required."
      }
    };
  },
  watch: {
    // lab_id() {
    //   this.populateShipments();
    // }
  },
  methods: {
    ...mapActions("shipments", ["getUnshipped"]),
    setShipmentValue(val) {
      this.anyChanges = true;
      this.$emit("input", val);
    },
    populateShipments() {
      this.loading = true;
      let params = {};
      // Filter by lab_id if one was provided
      // if (this.lab_id && parseInt(this.lab_id) > 0) params.lab_id = this.lab_id;
      return this.getUnshipped(params).then(({ data }) => {
        // Populate the shipments
        this.items = data.data;
        // If a value was not provided
        if (!this.value || !(parseInt(this.value) > 0)) {
          // and there is a shipment available
          if (this.items.length) {
            // Pre-select the first shipment
            this.setShipmentValue(this.items[0].id);
          }
        } else {
          // If selected shipment isn't available in the dataset, then we should unset it
          const shipment = this.items.find(item => item.id === this.value);
          if (shipment === undefined) {
            this.setShipmentValue(null);
          }
        }
        this.loading = false;
      });
    },
    handleClearShipment() {
      this.setShipmentValue(null);
      this.disabled = true;
    },
    handleEnableShipment() {
      this.disabled = false;

      let params = {};
      // Filter by lab_id if one was provided
      // if (this.lab_id && parseInt(this.lab_id) > 0) params.lab_id = this.lab_id;
      return this.getUnshipped(params).then(({ data }) => {
        // Populate the shipments
        this.items = data.data;
        // If a value was not provided
        if (!this.value || !(parseInt(this.value) > 0)) {
          // and there is a shipment available
          if (this.items.length) {
            // Pre-select the first shipment
            this.setShipmentValue(this.items[0].id);
          }
        } else {
          // If selected shipment isn't available in the dataset, then we should unset it
          const shipment = this.items.find(item => item.id === this.value);
          if (shipment === undefined) {
            this.setShipmentValue(null);
          }
        }
        this.loading = false;
      });
    }
  },
  mounted() {
    this.populateShipments();
  }
};
</script>

<style scoped></style>
