<template>
  <StepComponent>
    <v-row>
      <v-col>
        <LabSelection />
        <GeneralSampleInformation />
        <PackagesAndComponents />
        <!-- <SamplingInformation /> -->
      </v-col>
      <v-col>
        <!-- <ReviewImages /> -->
        <BillingInformation />
        <Price />
      </v-col>
    </v-row>
    <v-row
      style="display: flex; margin: auto; margin-top: 2rem;"
      class="img-container"
    >
      <template>
        <SampleImages :sample-id="active_sample.id" />
      </template>
    </v-row>
    <!-- <v-row>
      {{ active_sample }}
    </v-row> -->
    <!-- <v-row>join {{ active_sample.shipment_id }}</v-row> -->
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/newSamples/SampleWizardSteps/StepComponent";
import LabSelection from "@/components/newSamples/ReviewStepCards/LabSelection";
import BillingInformation from "@/components/newSamples/ReviewStepCards/BillingInformation";
import GeneralSampleInformation from "@/components/newSamples/ReviewStepCards/GeneralSampleInformation";
// import SamplingInformation from "@/components/newSamples/ReviewStepCards/SamplingInformation";
import PackagesAndComponents from "@/components/newSamples/ReviewStepCards/PackagesAndComponents";
// import ReviewImages from "@/components/samples/ReviewStepCards/ReviewImages";
import Price from "@/components/newSamples/ReviewStepCards/Price";
import SampleImages from "@/components/newSamples/components/SampleImages";
import { mapState } from "vuex";

export default {
  name: "ReviewStep",
  components: {
    // ReviewImages,
    StepComponent,
    LabSelection,
    BillingInformation,
    GeneralSampleInformation,
    // SamplingInformation,
    PackagesAndComponents,
    Price,
    SampleImages
  },
  computed: {
    ...mapState("samples", ["active_sample"])
  },
  mounted() {
    // this.active_sample._joinData.shipment_id = this.shipment_id;
    // this.active_sample.shipments[0].id = this.shipment_id;
  }
};
</script>
