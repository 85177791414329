<template>
  <StepComponent>
    <v-container class="my-3 pa-0">
      <v-row dense>
        <v-col>
          <div class="h6 py-2 font-weight-bold">Verify Billing</div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <VerifyBilling />
        </v-col>
      </v-row>
    </v-container>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
    <v-container class="my-3 pa-0">
      <v-row dense>
        <v-col>
          <div class="h6 py-2 font-weight-bold">Report Recipient</div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <CopyTo />
        </v-col>
      </v-row>
    </v-container>
    <v-spacer></v-spacer>
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/newSamples/SampleWizardSteps/StepComponent";
import VerifyBilling from "@/components/newSamples/SampleWizardSteps/SampleStepComponents/VerifyBilling";
import CopyTo from "@/components/newSamples/SampleWizardSteps/SampleStepComponents/CopyTo";

import { mapFields } from "vuex-map-fields";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "BillingStep",
  components: {
    StepComponent,
    VerifyBilling,
    CopyTo
  },
  computed: {
    ...mapGetters("clients", ["selectedClient"]),
    ...mapFields("sampleWizard", ["wizard.copy_to"]),
    shipment_id: {
      get() {
        const samples = this.$store.state.samples;
        if (
          !samples.active_sample.shipments ||
          !samples.active_sample.shipments.length
        )
          return null;
        return this.$store.state.samples.active_sample.shipments[0].id;
      },
      set(val) {
        this.updateActiveSample({ shipments: [{ id: val }] });
      }
    }
  },
  methods: {
    ...mapActions("samples", ["updateSampleSet"]),
    ...mapMutations("samples", ["updateActiveSample"]),
    beforeContinue() {
      return this.updateSampleSet("billing").then(res => {
        return !!res;
      });
    }
  }
};
</script>

<style scoped></style>
