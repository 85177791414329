<template>
  <StepComponent>
    <v-container>
      <div class="h6 py-2 font-weight-bold">General Sample Information</div>
      <v-row v-if="hasMultipleClients" dense>
        <v-col>
          <v-select
            label="Select a Client"
            v-model="client_id"
            :items="clients"
            item-value="id"
            item-text="title_with_id"
          />
        </v-col>
      </v-row>
      <!-- <LabSelection v-model="lab_id" /> -->
      <GeneralSampleInformation />
    </v-container>
    <v-container class="my-6">
      <!-- <div class="h6 py-2 font-weight-bold">Sampling Information</div>
      <SamplingInformation /> -->
      <!-- <SampleImages :sample-id="active_sample.id ? active_sample.id : null" /> -->
    </v-container>
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/samples/SampleWizardSteps/StepComponent";
// import SamplingInformation from "@/components/samples/SampleWizardSteps/SampleStepComponents/SamplingInformation";
import GeneralSampleInformation from "@/components/newSamples/SampleWizardSteps/SampleStepComponents/GeneralSampleInformation";
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
// import LabSelection from "@/components/labs/LabSelection";
import isString from "lodash.isstring";
// import SampleImages from "../components/SampleImages.vue";

export default {
  name: "SampleInformationStep",
  components: {
    StepComponent,
    GeneralSampleInformation
    // SampleImages
    // SamplingInformation,
    // LabSelection
  },
  watch: {
    client_id(clientId) {
      const client = this.clients.find(item => item.id === clientId);
      if (client && client.lab_id !== this.lab_id) {
        this.lab_id = client.lab_id;
      } else {
        this.getSampleTestComponents();
      }
    }
    // lab_id() {
    //   this.getSampleTestComponents();
    // }
  },
  computed: {
    ...mapFields("samples", [
      "active_sample.client_id",
      "active_sample.lab_id"
    ]),
    ...mapGetters("clients", ["selectedClient", "hasMultipleClients"]),
    ...mapState("clients", { clients: "items" }),
    ...mapState("samples", ["active_sample"])
  },
  methods: {
    ...mapActions("samples", ["addSample", "getSampleTestComponents"]),
    beforeContinue() {
      // Add sample to backend (or update if an ID exists on active_sample)
      return this.addSample().then(res => !!res);
    }
  },
  mounted() {
    this.loading = true;
    this.getSampleTestComponents()
      .then(() => {
        this.loading = false;
      })
      .catch(e => {
        // If an error is a string, set it as the error message for the wizard
        this.setError(isString(e) ? e : "An unknown error occurred.");
        // Log to console if this was a non-string error
        if (!isString(e)) console.error(e);
      });
  }
};
</script>

<style scoped></style>
